<!-- Stakeholders.vue -->
<template>
  <v-card-text>
    <div class="d-flex" style="width: 100%; margin-top: 20px">
      <v-row dense style="width: 100%">
        <v-col cols="6">
          <div class="d-flex">
            <v-autocomplete
              v-model="selectedStakeholder"
              style="
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              "
              :key="selectedStakeholder ? selectedStakeholder.key : 'null'"
              outlined
              auto-select-first
              clearable
              label="Stakeholders"
              :items="stakeholders"
              item-text="friendlyName"
              return-object
              color="primary"
            />
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.state.Permissions.StakeholderCreate"
                    style="
                      width: 20px;
                      height: 56px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    block
                    @click="addStakeholderToCampaign"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add stakeholder</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>

        <v-col cols="6">
          <div class="d-flex">
            <v-autocomplete
              v-model="selectedTeam"
              style="
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              "
              :key="selectedTeam ? selectedTeam.key : 'null'"
              outlined
              auto-select-first
              clearable
              label="Teams"
              :items="availableTeams"
              item-text="name"
              return-object
              color="primary"
            />
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="$store.state.Permissions.TeamCreate"
                    style="
                      width: 20px;
                      height: 56px;
                      border-top-left-radius: 0px;
                      border-bottom-left-radius: 0px;
                    "
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    block
                    @click="addTeamToCampaign"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add stakeholder/team</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- Team chips -->
    <v-row v-if="campaignTeamsFromApi.length > 0">
      <v-col cols="12" style="margin-top: -22px">
        <v-chip-group column class="d-flex flex-wrap">
          <v-chip
            v-for="(team, index) in campaignTeamsFromApi"
            :key="index"
            class="text-capitalize"
            style="
              border-radius: 5px;
              height: 45px;
              background-color: transparent;
              cursor: pointer;
            "
            :style="
              'border: 1px solid ' + stringToColor(team.name) + ' !important;'
            "
            @click="showTeamUsers(team)"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" style="font-size: medium">
                  {{ team.name.toUpperCase() }}
                  <v-icon
                    small
                    fab
                    @click.stop="
                      deleteTeamFromCampaignDialog = true;
                      selectedTeamForDelete = team;
                    "
                  >
                    mdi-close
                  </v-icon>
                </span>
              </template>
              <span style="font-size: medium">{{ team.name }} (Team)</span>
            </v-tooltip>
            <div
              style="
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: 0;
                left: 0;
              "
              :style="'background-color:' + stringToColor(team.name)"
            ></div>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <!-- USER VIEW -->
    <div
      v-if="campaignStakeholdersFromApi.length > 0"
      style="
        background-color: rgb(0 0 0 / 47%);
        border-radius: 5px;
        overflow: hidden;
        margin-top: 5px;
      "
    >
    <!-- Tabs -->
      <v-row v-if="campaignStakeholdersFromApi.length > 0">
        <v-col cols="12">
          <v-tabs
            v-model="selectedTab"
            background-color="transparent"
            color="primary"
            grow
          >
            <v-tab
              v-for="stakeholder in campaignStakeholdersFromApi"
              :key="stakeholder.id"
              @click="selectedAddedStakeholder = [stakeholder]"
            >
              <v-icon left>mdi-office-building</v-icon>
              {{ stakeholder.stakeholder.friendlyName }}
              <v-tooltip
                top
                v-if="loggedInUserStakeholderId == stakeholder.stakeholder.id"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-crown </v-icon>
                </template>
                <span>Your parent Stakeholder</span>
              </v-tooltip>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row
        v-if="selectedAddedStakeholder && selectedAddedStakeholder.length > 0"
        class="pa-0 ma-0"
      >
        <v-col cols="12">
          <div class="d-flex">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              clearable
              label="Search Users"
              single-line
              outlined
              hide-details
              class="mb-4"
              :disabled="usersAssociatedWithCampaign.length == 0"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"
              color="error"
              style="height: 56px; margin-left: 10px"
              @click="
                deleteStakeholderFromCampaignDialog = true;
                selectedStakeholderForDelete = selectedAddedStakeholder[0];
              "
            >
              <v-icon>mdi-office-building-remove</v-icon>
            </v-btn>
              </template>
              <span>Remove Stakeholder</span>
            </v-tooltip>
          </div>
          <!-- Data Table of users of selected stakeholder-->
          <v-data-table
            v-show="usersAssociatedWithCampaign.length != 0"
            :headers="headers"
            :items="filteredUsers"
            :search="search"
          >
            <template v-slot:item.added="{ item }">
              <div class="ma-2">
                <v-checkbox
                  v-model="item.addedToCampaign"
                  @change="toggleUserInCampaign(item)"
                />
              </div>
            </template>
            <template v-slot:item.image="{ item }">
              <div class="ma-2">
                <v-avatar size="60">
                  <img
                    :src="
                      item.contactCard.profilePicture
                        ? item.contactCard.profilePicture.urlThumb
                        : 'contact-placeholder.jpg'
                    "
                  />
                </v-avatar>
              </div>
            </template>
            <template v-if="burstId !== null" v-slot:item.isOwner="{ item }">
              <div class="">
                <v-checkbox
                  :on-icon="'mdi-crown'"
                  :off-icon="'mdi-crown-outline'"
                  v-model="item.isOwner"
                  @change="checkIfAdmin(item)"
                />
              </div>
            </template>
          </v-data-table>
          <v-col v-show="usersAssociatedWithCampaign.length < 1" class="text-center">
            <span style="text-align: center"
              ><v-icon>mdi-alert</v-icon> No users have been associated with
              this stakeholder.</span
            >
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <span style="text-align: center"
            >Select a stakeholder to view users.</span
          >
        </v-col>
      </v-row>
    </div>

    <v-row v-else style="padding-bottom: 20px">
      <v-col class="text-center mt-4">
        <span>No stakeholders have been added to this campaign.</span>
      </v-col>
    </v-row>
    <team-users-dialog
      v-model="teamUsersDialog"
      :team="selectedTeamForUsers"
      :users="selectedTeamUsers"
      :headers="headers"
      @close="closeTeamDialog"
    />

    <create-user-dialog
      v-model="promptCreateUserDialog"
      :roles="roles"
      @confirm="createUserAcc"
      @cancel="unselectUser"
    />

    <delete-stakeholder-dialog
      v-model="deleteStakeholderFromCampaignDialog"
      @confirm="deleteStakeholder"
      @cancel="
        deleteStakeholderFromCampaignDialog = false;
        selectedStakeholderForDelete = null;
      "
    />

    <delete-team-dialog
      v-model="deleteTeamFromCampaignDialog"
      :team="selectedTeamForDelete"
      @confirm="deleteTeamFromCampaign"
      @cancel="
        deleteTeamFromCampaignDialog = false;
        selectedTeamForDelete = null;
      "
    />
  </v-card-text>
</template>
<script>
import StakeholderController from "@/services/controllers/Stakeholder";
import UserController from "@/services/controllers/User";
import CampaignController from "@/services/controllers/Campaign";
import countryPhonePrefixes from "../../../public/country-phone-prefix.json";

import TeamUsersDialog from "./stakeholders/TeamUsersDialog.vue";
import CreateUserDialog from "./stakeholders/CreateUserDialog.vue";
import DeleteStakeholderDialog from "./stakeholders/DeleteStakeholderDialog.vue";
import DeleteTeamDialog from "./stakeholders/DeleteTeamDialog.vue";

import uniqBy from "lodash/uniqBy";

export default {
  components: {
    TeamUsersDialog,
    CreateUserDialog,
    DeleteStakeholderDialog,
    DeleteTeamDialog,
  },
  props: {
    campaignId: {
      required: true,
    },
    burstId: {
      type: Number,
      default: null,
    },
    ownerId: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    teamSearch: "",
    teamUsersDialog: false,
    selectedTeamUsers: [],
    selectedTeamForUsers: null,
    selectedTab: 0,
    search: "",
    selectedStakeholder: null,
    requiredRule: [(v) => !!v || "Field is required"],
    stakeholders: [],
    teams: [],
    addedStakeholders: [],
    validContactCard: false,
    stakeholderSearchString: null,
    selectedAddedStakeholder: null,
    // User ids
    loggedInUserStakeholderId: null,
    loggedInUserId: null,
    selectedStakeholderForDelete: null,
    selectedRole: null,
    deleteStakeholderFromCampaignDialog: false,
    deleteTeamFromCampaignDialog: false,
    selectedTeamForDelete: null,
    promptCreateUserDialog: false,
    editContactCardDialog: false,
    createdContactCardId: null,
    countryCodeOptions: countryPhonePrefixes,
    uploadedContactCardLogo: null,
    addContactDialog: false,
    selectedUserForEdit: null,
    // Stakeholders
    usersAssociatedWithCampaign: [],
    campaignStakeholdersFromApi: [],
    campaignTeamsFromApi: [],
    selectedAddedTeam: null,
    selectedTeam: null,
    originalCampaignStakeholders: [],
    imgUrl: null,
    mediaUploadId: null,
    selectedContact: null,
    roles: [],
    newContactCard: {
      firstName: null,
      surname: null,
      countryCode: null,
      phoneNumber: null,
      email: null,
      jobTitle: null,
    },
    headers: [
      {
        sortable: false,
        value: "added",
      },
      {
        sortable: false,
        value: "image",
      },
      {
        sortable: true,
        text: "Forename",
        value: "contactCard.firstName",
      },
      {
        sortable: true,
        text: "Surname",
        value: "contactCard.surname",
      },
      {
        sortable: true,
        text: "Title",
        value: "contactCard.jobTitle",
      },
      {
        sortable: false,
        text: "Email",
        value: "contactCard.email",
      },
    ],
  }),

  watch: {
    async selectedTab(newVal) {
    if (this.campaignStakeholdersFromApi[newVal]) {
      const stakeholder = this.campaignStakeholdersFromApi[newVal];
      this.selectedAddedStakeholder = [stakeholder];
      
      // Force refresh the data
      await UserController.searchContactCardsById(
        stakeholder.stakeholder.id
      ).then(async (contactsInStakeholders) => {
        await this.processContactsInStakeholder(contactsInStakeholders);
      });
    }
  },
    async selectedAddedStakeholder(value, oldValue) {
      console.log("value", value);
      if (value !== null && value.length != 0) {
        // Get users for stakeholder id
        await UserController.searchContactCardsById(
          value[0].stakeholder.id
        ).then(async (contactsInStakeholders) => {
          await this.processContactsInStakeholder(contactsInStakeholders);
        });
      }
    },
    // When selected stakeholder is changed, check if it is already added to the campaign, if not, get all contacts in stakeholder and check if they are added to the campaign
    selectedStakeholder(value, oldValue) {
      return;
      if (value) {
        // Get users for stakeholder id
        UserController.searchContactCardsById(value.id).then(
          (contactsInStakeholders) => {
            CampaignController.getAllUsers(this.campaignId).then((res) => {
              for (var i = 0; i < contactsInStakeholders.data.length; i++) {
                // match id of contact card with Id of users added to campaign
                for (var j = 0; j < res.data.length; j++) {
                  if (contactsInStakeholders.data[i].user) {
                    if (
                      contactsInStakeholders.data[i].user.id === res.data[j].id
                    ) {
                      contactsInStakeholders.data[i].addedToCampaign = true;
                    }
                  }
                }
              }
              this.usersAssociatedWithCampaign = contactsInStakeholders.data;
            });
          }
        );
      }
    },
  },
  mounted() {
        // console log all the vuex store objects User.user.id
        console.log("weqewq",this.$store.state.User.user.id);
    if (this.burstId !== null) {
      this.headers.push({
        sortable: false,
        text: "Burst Owner",
        value: "isOwner",
      });
    }
  },
  async created() {
    this.getRoles();
    await this.getUser();
    this.getAllStakeholders();
    this.getAllTeams();
    await this.getStakeholdersInCampaign();
    await this.getTeamsInCampaign();
  },
  computed: {
    availableTeams() {
    return this.teams.filter(team => 
      !this.campaignTeamsFromApi.some(campaignTeam => 
        campaignTeam.id === team.id
      )
    );
  },
    filteredUsers() {
      if (!this.search) {
        return this.sortedUsersAssociatedWithCampaign;
      }

      const searchTerm = this.search.toLowerCase();
      return this.sortedUsersAssociatedWithCampaign.filter((user) => {
        const contactCard = user.contactCard;
        return (
          (contactCard.firstName &&
            contactCard.firstName.toLowerCase().includes(searchTerm)) ||
          (contactCard.surname &&
            contactCard.surname.toLowerCase().includes(searchTerm)) ||
          (contactCard.jobTitle &&
            contactCard.jobTitle.toLowerCase().includes(searchTerm)) ||
          (contactCard.email &&
            contactCard.email.toLowerCase().includes(searchTerm))
        );
      });
    },
    sortedUsersAssociatedWithCampaign() {
      // Create a deep clone of the array to avoid reference issues
      const sortedUsers = JSON.parse(
        JSON.stringify(this.usersAssociatedWithCampaign)
      );

      // Sort the array: true values first, then false values
      return sortedUsers.sort((a, b) => {
        if (a.addedToCampaign === b.addedToCampaign) return 0;
        return a.addedToCampaign ? -1 : 1;
      });
    },
  },
  methods: {
    closeTeamDialog() {
      this.teamUsersDialog = false;
      this.teamSearch = ""; // Reset the search when closing
      this.selectedTeamUsers = [];
      this.selectedTeamForUsers = null;
    },

    async showTeamUsers(team) {
      this.selectedTeamForUsers = team;
      const users = await this.getAllTeamUsers(team.id);
      this.selectedTeamUsers = users;
      this.teamSearch = ""; // Reset the search when opening
      this.teamUsersDialog = true;
    },

    stringToColor(str) {
      // Create a hash from the string
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      // Create a color from the hash
      const r = (hash & 0xff0000) >> 16;
      const g = (hash & 0x00ff00) >> 8;
      const b = hash & 0x0000ff;

      // Convert to a CSS-compatible color
      return `rgb(${r}, ${g}, ${b})`;
    },

    async checkIfAdmin(item) {
      let arr = [...this.usersAssociatedWithCampaign];
      arr.forEach((contact) => {
        if (contact.contactCard.id === item.contactCard.id) {
          contact.isOwner = true;
        } else {
          contact.isOwner = false;
        }
      });
      this.usersAssociatedWithCampaign = arr;
      if (item.isOwner) {
        await CampaignController.setBurstOwner({
          id: this.burstId,
          ownerId: item.user.id,
        })
          .then((res) => {
            this.$root.$emit(
              "snackbarSuccess",
              "Burst owner changed successfully"
            );
            if (this.type === "manageBurst") {
              this.$root.$emit(
                "updateBurstOwnerManageBursts",
                res.data,
                this.burstId
              );
            } else {
              this.$root.$emit("updateBurstOwner", res.data, this.burstId);
            }
          })
          .catch((err) => {
            this.$root.$emit("snackbarError", err);
            console.log(err);
          });
      } else {
        this.$root.$emit(
          "snackbarWarning",
          "This user is already the owner of this burst"
        );
      }
      this.$nextTick();
    },

    async getUser() {
      const res = await UserController.whoAmI().catch((err) => {
        this.$root.$emit("snackbarError", "" + err.response.data.message);
      });

      if (res && res.data && res.data.contactCard) {
        this.loggedInUserStakeholderId = res.data.contactCard.stakeholder.id;
        this.loggedInUserId = res.data.contactCard.id;
      }
    },

    unselectUser() {
      this.promptCreateUserDialog = false;
      this.createdContactCardId = null;
      this.selectedContact.addedToCampaign = null;
    },

    async toggleUserInCampaign(item) {
  try {
    if (item.addedToCampaign === false) {
      // User is being unchecked
      if (item.user) {
        // Check if user belongs to any teams in the campaign
        const userTeams = [];
        for (const team of this.campaignTeamsFromApi) {
          const teamUsers = await this.getAllTeamUsers(team.id);
          if (teamUsers.some(teamUser => teamUser.id === item.user.id)) {
            userTeams.push(team);
          }
        }

        // Remove teams that this user belongs to
        for (const team of userTeams) {
          // Make API call to remove team before updating UI
          await UserController.removeTeamFromCampaign(
            this.campaignId,
            team.id
          );
          
          const teamIndex = this.campaignTeamsFromApi.findIndex(t => t.id === team.id);
          if (teamIndex !== -1) {
            this.campaignTeamsFromApi.splice(teamIndex, 1);
          }
        }

        // Remove the user from the campaign
        await this.deleteUserFromCampaign(item.user.id, item);
      }
    } else {
      // Adding user to campaign
      var stakeholderExists = this.originalCampaignStakeholders.find(
        (stakehold) =>
          parseInt(stakehold.stakeholder.id) ===
          parseInt(item.contactCard.stakeholder.id)
      );

      if (stakeholderExists !== undefined) {
        if (item.user) {
          await this.addUserToCampaign(item.user.id, undefined, false);
        } else {
          this.selectedContact = item;
          this.promptCreateUserDialog = true;
          this.createdContactCardId = item.contactCard.id;
        }
      } else if (stakeholderExists === undefined && item.addedToCampaign === true) {
        this.$root.$emit("snackbarSuccess", "Successfully added stakeholder");
        try {
          await CampaignController.setStakeholders({
            campaignId: this.campaignId,
            campaignStakeholders: this.formatStakeholdersArray(),
          });
          
          if (item.user) {
            await this.addUserToCampaign(item.user.id, undefined, false);
          } else {
            this.selectedContact = item;
            this.promptCreateUserDialog = true;
            this.createdContactCardId = item.contactCard.id;
          }
          await this.getStakeholdersInCampaignAfterAdd();
        } catch (err) {
          console.error(err);
        }
      }
    }
  } catch (error) {
    console.error('Error in toggleUserInCampaign:', error);
    // Revert the checkbox state if there's an error
    item.addedToCampaign = !item.addedToCampaign;
    this.$root.$emit("snackbarError", "Failed to update user status");
  }
},

async checkIfTeamShouldBeAdded(userId) {
  try {
    for (const team of this.teams) {
      const teamUsers = await this.getAllTeamUsers(team.id);
      const campaignUsers = await CampaignController.getAllUsers(this.campaignId);
      
      const allTeamUsersInCampaign = teamUsers.every(teamUser =>
        campaignUsers.data.some(campaignUser => campaignUser.id === teamUser.id)
      );
      
      const userInTeam = teamUsers.some(teamUser => teamUser.id === userId);
      
      if (allTeamUsersInCampaign && 
          userInTeam &&
          !this.campaignTeamsFromApi.some(t => t.id === team.id)) {
        // Make API call to add team before updating UI
        await UserController.addTeamToCampaign({
          campaignId: this.campaignId,
          teamId: team.id,
        });
        this.campaignTeamsFromApi.push(team);
      }
    }
  } catch (error) {
    console.error('Error in checkIfTeamShouldBeAdded:', error);
  }
},

async deleteUserFromCampaign(userId, userItem = null) {
  try {
    const res = await CampaignController.deleteUser({
      campaignId: this.campaignId,
      userId: userId,
    });
    this.$root.$emit("snackbarSuccess", res.data.message);
  } catch (err) {
    this.$root.$emit("snackbarError", "" + err.response.data.message);
    if (userItem) {
      userItem.addedToCampaign = true;
    }
  }
},

async deleteTeamFromCampaign() {
  try {
    const currentUserId = this.$store.state.User.user.id; // Get current user's ID
    // Get all users in the team
    const teamUsers = await this.getAllTeamUsers(this.selectedTeamForDelete.id);

    // For each user in the team
    for (const teamUser of teamUsers) {
      // Skip if this is the current user
      if (teamUser.id === currentUserId) continue;

      // Get the user's current state in the campaign
      const campaignUser = this.usersAssociatedWithCampaign.find(
        u => u.user && u.user.id === teamUser.id
      );

      if (campaignUser) {
        // Check if user was added only because of this team
        const isInOtherTeams = await this.isUserInOtherTeams(
          teamUser.id,
          this.selectedTeamForDelete.id
        );
        const wasManuallyAdded = await this.wasUserManuallyAdded(teamUser.id);

        // If user was only added by this team, remove them
        if (!isInOtherTeams && !wasManuallyAdded) {
          campaignUser.addedToCampaign = false;
          await this.deleteUserFromCampaign(teamUser.id, campaignUser);

          // Check if we need to remove their stakeholder
          const stakeholder = this.campaignStakeholdersFromApi.find(
            s => s.stakeholder.id === campaignUser.contactCard.stakeholder.id
          );

          if (stakeholder) {
            const stakeholderHasOtherUsers = await this.stakeholderHasOtherActiveUsers(
              stakeholder.stakeholder.id,
              teamUser.id
            );

            if (!stakeholderHasOtherUsers) {
              // Remove stakeholder if it has no other active users
              const index = this.campaignStakeholdersFromApi.findIndex(
                s => s.stakeholder.id === stakeholder.stakeholder.id
              );
              this.campaignStakeholdersFromApi.splice(index, 1);

              // Add the stakeholder back to the dropdown options
              this.stakeholders.push(stakeholder.stakeholder);

              // Sort stakeholders by friendlyName
              this.stakeholders.sort((a, b) =>
                a.friendlyName.localeCompare(b.friendlyName)
              );
            }
          }
        }
      }
    }

    // Remove team from campaign in API
    await UserController.removeTeamFromCampaign(
      this.campaignId,
      this.selectedTeamForDelete.id
    );

    // Remove team from UI
    const teamIndex = this.campaignTeamsFromApi.findIndex(
      t => t.id === this.selectedTeamForDelete.id
    );
    if (teamIndex !== -1) {
      this.campaignTeamsFromApi.splice(teamIndex, 1);
    }

    // Update stakeholders in campaign
    await CampaignController.setStakeholders({
      campaignId: this.campaignId,
      campaignStakeholders: this.formatStakeholdersArray(),
    });

    // Refresh teams list
    await this.getAllTeams();

    // Refresh the current stakeholder's users
    if (this.selectedAddedStakeholder && this.selectedAddedStakeholder.length > 0) {
      await UserController.searchContactCardsById(
        this.selectedAddedStakeholder[0].stakeholder.id
      ).then(async (contactsInStakeholders) => {
        await this.processContactsInStakeholder(contactsInStakeholders);
      });
    }

    this.$root.$emit("snackbarSuccess", "Team removed from campaign");
  } catch (error) {
    console.error('Error in deleteTeamFromCampaign:', error);
    this.$root.$emit("snackbarError", "Failed to remove team from campaign");
  } finally {
    this.selectedTeamForDelete = null;
    this.deleteTeamFromCampaignDialog = false;
  }
},

// Helper methods
async isUserInOtherTeams(userId, excludeTeamId) {
  for (const team of this.campaignTeamsFromApi) {
    if (team.id !== excludeTeamId) {
      const teamUsers = await this.getAllTeamUsers(team.id);
      if (teamUsers.some(user => user.id === userId)) {
        return true;
      }
    }
  }
  return false;
},

async wasUserManuallyAdded(userId) {
  // You'll need to implement this based on your data structure
  // This should return true if the user was manually added to the campaign
  // rather than being added as part of a team
  return false; // Implement your logic here
},

async stakeholderHasOtherActiveUsers(stakeholderId, excludeUserId) {
  const stakeholderUsers = this.usersAssociatedWithCampaign.filter(
    user => 
      user.contactCard.stakeholder.id === stakeholderId &&
      user.user &&
      user.user.id !== excludeUserId &&
      user.addedToCampaign
  );
  
  return stakeholderUsers.length > 0;
},

    async processContactsInStakeholder(contactsInStakeholders) {
      await CampaignController.getAllUsers(this.campaignId).then((res) => {
        const processedContacts = [...contactsInStakeholders.data];

        for (var i = 0; i < processedContacts.length; i++) {
          for (var j = 0; j < res.data.length; j++) {
            if (processedContacts[i].user) {
              if (processedContacts[i].user.id === res.data[j].id) {
                processedContacts[i].addedToCampaign = true;
              }
            }
          }
        }

        this.$set(this, "usersAssociatedWithCampaign", processedContacts);

        const hasOwner = this.usersAssociatedWithCampaign.find(
          (contact) => contact.owner !== null
        );

        if (this.ownerId || hasOwner) {
          this.usersAssociatedWithCampaign.forEach((contact) => {
            if (contact.owner === null) {
              contact.isOwner = false;
            }
            if (
              contact.contactCard.id ===
              (this.ownerId ? this.ownerId : hasOwner.contactCard.id)
            ) {
              contact.isOwner = true;
            }
          });
        }
      });
    },

    getStakeholdersInCampaign() {
      CampaignController.getCampaign(this.campaignId).then((res) => {
        this.campaignStakeholdersFromApi = res.data.campaignStakeholders;
        this.originalCampaignStakeholders = [
          ...this.campaignStakeholdersFromApi,
        ];
        this.selectedAddedStakeholder =
          this.campaignStakeholdersFromApi.length !== 0
            ? [this.campaignStakeholdersFromApi[0]]
            : null;
        this.removeAlreadyAddedStakeholders();
      });
    },

    getTeamsInCampaign() {
      UserController.getCampaignTeams(this.campaignId).then((res) => {
        this.campaignTeamsFromApi = res.data;
        console.log("cteams", this.campaignTeamsFromApi);
      });
    },

    async getStakeholdersInCampaignAfterAdd() {
      this.originalCampaignStakeholders.push(this.selectedAddedStakeholder[0]);
      this.selectedStakeholder = null;
      this.removeAlreadyAddedStakeholders();
      this.$nextTick();
    },

    formatStakeholdersArray() {
      const allStakeholderArr = [];
      for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
        allStakeholderArr.push({
          stakeholderId: this.campaignStakeholdersFromApi[i].stakeholder.id,
          position: i,
        });
      }
      return allStakeholderArr;
    },

    async addStakeholderToHoldingList(incomingStakeholder) {
      let obj = {
        id: incomingStakeholder.id,
        campaignId: this.campaignId,
        stakeholder: incomingStakeholder,
        position: this.campaignStakeholdersFromApi.length + 1,
        key: incomingStakeholder.id,
      };
      this.campaignStakeholdersFromApi.push(obj);
      this.selectedAddedStakeholder = [obj];
      this.selectedTab = this.campaignStakeholdersFromApi.length - 1;
    },

    async addStakeholderToCampaign() {
      if (this.selectedStakeholder === null) {
        this.$root.$emit(
          "snackbarError",
          "Please select a stakeholder to add to the campaign."
        );
        return;
      }

      await this.addStakeholderToHoldingList(this.selectedStakeholder);
      await this.setStakeholders("Stakeholder added to campaign.", false);
    },

    async setStakeholders(successMessage, multiAdd) {
      CampaignController.setStakeholders({
        campaignId: this.campaignId,
        campaignStakeholders: this.formatStakeholdersArray(),
      })
        .then(async (res) => {
          this.$root.$emit("snackbarSuccess", successMessage);
          if (!multiAdd) await this.getStakeholdersInCampaignAfterAdd();
          else await this.getStakeholdersInCampaign();
        })
        .catch((err) => {
          this.$root.$emit("snackbarError", "" + err.response.data.message);
        });
    },

    async addTeamToCampaign() {
  if (this.selectedTeam === null) {
    this.$root.$emit(
      "snackbarError",
      "Please select a team to add to the campaign."
    );
    return;
  }

  try {
    // First add the team to campaign
    await UserController.addTeamToCampaign({
      campaignId: this.campaignId,
      teamId: this.selectedTeam.id,
    });

    // Store the selected team ID before clearing it
    const teamId = this.selectedTeam.id;
    this.selectedTeam = null;

    // Update teams list
    await this.getTeamsInCampaign();

    // Add users and update stakeholders
    await this.addUsersOfTeamToCampaign(teamId);

    this.$root.$emit("snackbarSuccess", "Team added to campaign.");
  } catch (err) {
    this.$root.$emit("snackbarError", "" + err.response.data.message);
  }
},

async addUsersOfTeamToCampaign(teamId) {
  try {
    // Get all team users
    const allTeamUsers = await this.getAllTeamUsers(teamId);
    
    // Collect all stakeholders first
    const teamStakeholders = uniqBy(
      allTeamUsers.map(user => user.contactCard.stakeholder),
      'id'
    );

    // Add stakeholders first
    let newStakeholdersAdded = false;
    let firstNewStakeholder = null;

    for (const stakeholder of teamStakeholders) {
      let obj = this.campaignStakeholdersFromApi.find(
        (stakehold) => stakehold.stakeholder.id === stakeholder.id
      );
      if (obj === undefined) {
        newStakeholdersAdded = true;
        const newStakeholderObj = {
          id: stakeholder.id,
          campaignId: this.campaignId,
          stakeholder: stakeholder,
          position: this.campaignStakeholdersFromApi.length + 1,
          key: stakeholder.id,
        };
        this.campaignStakeholdersFromApi.push(newStakeholderObj);
        
        if (!firstNewStakeholder) {
          firstNewStakeholder = newStakeholderObj;
        }
      }
    }

    // Update stakeholders in campaign
    await this.setStakeholders(
      "Stakeholders of team members added to campaign.",
      true
    );

    // Add all users
    for (const user of allTeamUsers) {
      await this.addUserToCampaign(
        user.id,
        "User of team added to campaign.",
        true
      );
    }

    // Update the selected tab and refresh data
    if (newStakeholdersAdded && firstNewStakeholder) {
      const newTabIndex = this.campaignStakeholdersFromApi.findIndex(
        s => s.stakeholder.id === firstNewStakeholder.stakeholder.id
      );
      
      // Set the tab and selected stakeholder
      this.selectedTab = newTabIndex;
      this.selectedAddedStakeholder = [firstNewStakeholder];
      
      // Force refresh the data for the new stakeholder
      const contactsInStakeholders = await UserController.searchContactCardsById(
        firstNewStakeholder.stakeholder.id
      );
      await this.processContactsInStakeholder(contactsInStakeholders);
    } else {
      // Refresh current stakeholder's data if no new stakeholders were added
      if (this.selectedAddedStakeholder && this.selectedAddedStakeholder.length > 0) {
        const contactsInStakeholders = await UserController.searchContactCardsById(
          this.selectedAddedStakeholder[0].stakeholder.id
        );
        await this.processContactsInStakeholder(contactsInStakeholders);
      }
    }

  } catch (error) {
    console.error('Error in addUsersOfTeamToCampaign:', error);
    this.$root.$emit("snackbarError", "Failed to add team users to campaign");
  }
},
    async addUserToCampaign(userId, successMessage, bypassErrorMessage) {
  await CampaignController.setUsers({
    campaignId: this.campaignId,
    userId: userId,
  })
    .then(async (res) => {
      this.$root.$emit(
        "snackbarSuccess",
        successMessage ?? res.data.message
      );
      await this.checkIfTeamShouldBeAdded(userId);
    })
    .catch((err) => {
      if (!bypassErrorMessage)
        this.$root.$emit("snackbarError", "" + err.response.data.message);
    });
},

    async getAllTeamUsers(teamId) {
      const res = await UserController.getTeamUsers(teamId);
      return res.data;
    },

    createUserAcc(roleId) {
      UserController.createUserFromContactCard({
        contactCardId: this.createdContactCardId,
        roleId: roleId,
      })
        .then((res) => {
          this.addUserToCampaign(
            res.data.id,
            "User account created successfully and user added to campaign.",
            false
          );
          this.createdContactCardId = null;
          this.selectedRole = null;
          this.selectedContact = null;
          this.promptCreateUserDialog = false;
          this.getAllStakeholders();
        })
        .catch((err) => {
          this.$root.$emit("snackbarError", "" + err.response.data.message);
        });
    },

    getRoles() {
      StakeholderController.getPrimaryRoles().then((res) => {
        this.roles = res.data;
      });
    },

    async deleteUserFromCampaign(userId, userItem = null) {
  try {
    const res = await CampaignController.deleteUser({
      campaignId: this.campaignId,
      userId: userId,
    });
    this.$root.$emit("snackbarSuccess", res.data.message);
  } catch (err) {
    this.$root.$emit("snackbarError", "" + err.response.data.message);
    if (userItem) {
      userItem.addedToCampaign = true;
    }
  }
},

async deleteStakeholder() {
  try {
    const currentUserId = this.$store.state.User.user.id; // Get current user's ID
    // Get all users in this stakeholder
    const stakeholderUsers = this.usersAssociatedWithCampaign.filter(
      user => user.contactCard.stakeholder.id === this.selectedStakeholderForDelete.stakeholder.id
    );

    // For each user in the stakeholder
    for (const user of stakeholderUsers) {
      // Skip if this is the current user
      if (user.user && user.user.id === currentUserId) continue;

      if (user.addedToCampaign && user.user) {
        // Uncheck the user
        user.addedToCampaign = false;
        
        // Check if this user is part of any teams
        const userTeams = [];
        for (const team of this.campaignTeamsFromApi) {
          const teamUsers = await this.getAllTeamUsers(team.id);
          if (teamUsers.some(teamUser => teamUser.id === user.user.id)) {
            userTeams.push(team);
          }
        }

        // Remove any teams this user was part of
        for (const team of userTeams) {
          await UserController.removeTeamFromCampaign(
            this.campaignId,
            team.id
          );
          const teamIndex = this.campaignTeamsFromApi.findIndex(t => t.id === team.id);
          if (teamIndex !== -1) {
            this.campaignTeamsFromApi.splice(teamIndex, 1);
          }
        }

        // Remove user from campaign
        await this.deleteUserFromCampaign(user.user.id, user);
      }
    }

    // Remove stakeholder from campaign
    const index = this.campaignStakeholdersFromApi.findIndex(
      stakeholder => stakeholder.id === this.selectedStakeholderForDelete.id
    );
    this.campaignStakeholdersFromApi.splice(index, 1);

    // Add the stakeholder back to the dropdown options
    this.stakeholders.push(this.selectedStakeholderForDelete.stakeholder);
    
    // Sort stakeholders by friendlyName
    this.stakeholders.sort((a, b) => 
      a.friendlyName.localeCompare(b.friendlyName)
    );

    // Update stakeholders in campaign
    await CampaignController.setStakeholders({
      campaignId: this.campaignId,
      campaignStakeholders: this.formatStakeholdersArray(),
    });

    this.$root.$emit("snackbarSuccess", "Successfully deleted stakeholder");
    this.deleteStakeholderFromCampaignDialog = false;
    this.selectedStakeholderForDelete = null;

    // Update UI state
    if (this.campaignStakeholdersFromApi.length > 0) {
      if (this.selectedTab >= this.campaignStakeholdersFromApi.length) {
        this.selectedTab = this.campaignStakeholdersFromApi.length - 1;
      }
      this.selectedAddedStakeholder = [
        this.campaignStakeholdersFromApi[this.selectedTab]
      ];
    } else {
      this.selectedTab = 0;
      this.selectedAddedStakeholder = null;
    }

    this.selectedStakeholder = null;
    this.usersAssociatedWithCampaign = [];
  } catch (error) {
    console.error('Error in deleteStakeholder:', error);
    this.$root.$emit("snackbarError", "Failed to delete stakeholder");
  }
},

    removeAlreadyAddedStakeholders() {
      for (var i = 0; i < this.campaignStakeholdersFromApi.length; i++) {
        for (var j = 0; j < this.stakeholders.length; j++) {
          if (
            this.campaignStakeholdersFromApi[i].stakeholder.id ===
            this.stakeholders[j].id
          ) {
            this.stakeholders.splice(j, 1);
          }
        }
      }
    },

    clearSearch() {
      this.stakeholderSearchString = null;
      this.getAllStakeholders();
    },

    searchForStakeholder() {
      StakeholderController.searchStakeholders(
        this.stakeholderSearchString
      ).then((res) => {
        this.stakeholders = res.data;
        this.removeAlreadyAddedStakeholders();
      });
    },

    getAllStakeholders() {
      StakeholderController.allStakeholders().then((res) => {
        this.stakeholders = res.data;
        this.removeAlreadyAddedStakeholders();
      });
    },

    getAllTeams() {
      UserController.getTeams().then((res) => {
        this.teams = res.data;
      });
    },
  },
};
</script>
