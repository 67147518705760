<template>
  <div
    @click="clickCard(campaign.id)"
    :style="{ height: `inherit` }"
  >
    <theme-material-card
      image
      min-width="300"
      max-width="300"
      color="#DDDDDD"
      class="mouse-pointer pa-4"
      :style="{ height: `100%` }"
    >
      <!-- Logo -->
      <template v-slot:image>
        <v-img
          v-if="campaign.logo"
          :src="campaign.logo.urlThumb"
          height="250"
          width="300"
          style="cursor: pointer"
        />
        <v-img
          v-else
          src="d-clamp-holder.jpg"
          width="300"
          height="250"
          style="cursor: pointer"
        />
      </template>

      <!-- Name -->
      <v-card-title
        class="justify-center font-weight-bold pb-1"
        style="word-break: break-word; text-align: center"
      >
        {{ campaign.name }}
      </v-card-title>
    
      <!-- Description -->
      <v-card-text
        class="body-1 text-center font-weight-light pb-1"
        :style=" { 
          margin: 0, 
          padding: 0, 
          marginBottom: `${2}rem`, 
          color: invertColor('#333', $vuetify.theme.dark) 
        }"
      >
        {{ (hideText(campaign.description, 100, true)) }}
      </v-card-text>

      <!-- kNumber -->
      <v-card-text
        v-if="$store.state.Permissions.campaignKNumberView"
        class="d-flex justify-center"
        :style="{ 
          position: 'absolute',
          bottom: `${8}px`, 
          left: 0, 
          right: 0, 
          color: invertColor('#333', $vuetify.theme.dark), 
          fontSize: `${14}px` 
        }"
      >
        {{ (campaign.kNumber && campaign.kNumber.length > 0) ? '#' + campaign.kNumber : null }}
      </v-card-text>

      <!-- Start date and End date -->
      <v-card-text
        class="d-flex justify-center" 
        :style="{ 
          position: 'absolute', 
          bottom: `${-10}px`, 
          left: '-15px', 
          right: 0,
          fontSize: `${14}px`,
          color: invertColor('#333', $vuetify.theme.dark),
        }"
      >
        {{ campaign.startDate | Moment_DD_MMM_YYYY }} - {{ campaign.endDate | Moment_DD_MMM_YYYY }}
      </v-card-text>

      <!-- Actions left -->
      <div
        v-if="$store.state.Permissions.campaignEdit || $store.state.Permissions.usersAndStakeholders"
        class="d-flex" 
        :style="{ 
          position: 'absolute', 
          left: '0px', 
          bottom: 0, 
          height: '26px', 
          width: '30px', 
          zIndex: '10' 
        }"
      >
      
      </div>

      <!-- Actions right -->
      <div
        v-if="$store.state.Permissions.campaignEdit || $store.state.Permissions.usersAndStakeholders"
        class="d-flex" 
        :style="{ 
          position: 'absolute', 
          right: '45px', 
          bottom: '3px', 
          height: '26px', 
          width: '30px' 
        }"
      >
        <div
          v-if="$store.state.Permissions.usersAndStakeholders" 
          @click.stop="clickAction(campaign, 'showStakeholdersDialog')"
          class="px-1"
        >
          <v-icon color="tertiary">mdi-account-multiple</v-icon>
        </div>
        <div
          v-if="$store.state.Permissions.campaignEdit"
          @click.stop="clickAction(campaign, 'showCampaignEditFormDialog')"
          class="px-1"
        >
          <v-icon color="primary">mdi-pencil</v-icon>
        </div>
      </div>
    </theme-material-card>
  </div>
</template>

<script>
  // Mixins
  import helpersConcatText from '@/mixins/helpers/concatText'
  import helpersColor from '@/mixins/helpers/color'

  // External libraries
  import moment from 'moment'

  export default {
    mixins: [
      helpersConcatText,
      helpersColor,
    ],

    filters: {
      Moment_DD_MMM_YYYY: function (date) {
        return moment(date).format('DD MMM YYYY')
      },
    },

    props: {
      campaign: {
        type: Object,
        required: true
      },
    },

    methods: {
      clickCard(id) {
        this.$emit('clickCard', id)
      },

      clickAction(selectedCampaign, action) {
        this.$emit('clickAction', selectedCampaign, action)
      }
    },
  }
</script>