<template>
    <v-dialog v-model="show" width="600">
      <v-card class="pa-4">
        <span>This contact has no user account, would you like to create a user
          account for this contact and then add to campaign?</span>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedRole"
              label="Select Role For New User*"
              :items="roles"
              item-text="name"
              :item-value="'id'"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="selectedRole === null"
            @click="$emit('confirm', selectedRole)"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="cancel"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    name: 'CreateUserDialog',
    props: {
      value: Boolean,
      roles: Array
    },
    data: () => ({
      selectedRole: null
    }),
    computed: {
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      cancel() {
        this.selectedRole = null;
        this.$emit('cancel');
      }
    }
  }
  </script>
  