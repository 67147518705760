import api from "@/services/api"
export default {
    // Returns a template of a master linear campaign profile if set to false / all of the profiles if set to true
    getDProfiles(data){
        return api().get("IssueTask/GetDProfiles?showHidden="+data)
    },
    // Apply a template profile to a campaign burst
    applyDProfiles(dProfileId, campaignBurstId){
        return api().get("IssueTask/ApplyDProfile?dProfileId="+dProfileId+"&campaignBurstId="+campaignBurstId)
    },
    // Fetches the tasks for a campaign burst
    getCampaignBurstMilestone(campaignBurstId){
        return api().get("IssueTask/GetCampaignBurstMilestone?campaignBurstId="+campaignBurstId)
    },
    // Sets the state of a task
    setDTaskState(dIssueId, campaignBurstScreenId, status){
        return api().put("IssueTask/SetDTaskState?dIssueId="+dIssueId+"&campaignBurstScreenId="+campaignBurstScreenId+'&complete='+status)
    },
    // Fetches all categories 
    getAllDCategories(){
        return api().get("IssueTask/GetDCategories")
    },
    // Fetches all issues 
    getAllDIssues(){
        return api().get("IssueTask/GetDIssues")
    },
    // Remove a milestone profile from a burst and all associated screen tasks
    removeProfileMilestone(burstId){
        return api().delete("IssueTask/RemoveDProfile?campaignBurstId="+burstId)
    },
    /* ---- json body ----  add a new (or existing) issue to a burst and it's cloned profile
        {
            "DIssueId": null, // DIssueId or null if creating a new issue
            "CampaignBurstId": 6, // the burst in question
            "Title": "Server Checks", // the title if a new issue
            "Description": "Check server that all is in order for Go Live", // the description if a new issue
            "StartDateOffset" : 5, // either an offset in days from the start (can be negative) ....
            "EndDateOffset": null, // or from the end date (can be negative)
            "CategoryId": 5 // the category id for the new issue
        } 
    */

    addIssueToBurst(data){
        return api().post("IssueTask/AddDIssueToBurst", data)
    },
    deleteCustomIssue(cbId, dIssueId){
        return api().delete('IssueTask/RemoveDTask?campaignBurstId='+cbId+'&dIssueId='+dIssueId)
    },
    //api/IssueTask/GetBellNotifications?top=X
    getBellNotifications(top, mode){
        return api().get('IssueTask/GetBellNotifications?top='+top+'&mode='+mode)
    },
}