<!-- TeamUsersDialog.vue -->
<template>
  <v-dialog v-model="show" width="800">
    <v-card style="display: flex; flex-direction: column; max-height: 90vh;">
      <!-- Fixed Header -->
      <v-card-title class="white--text primary">
        {{ team ? team.name : '' }} Team Members
      </v-card-title>

      <!-- Fixed Search Bar -->
      <v-card-text class="pa-4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          label="Search Team Members"
          single-line
          outlined
          hide-details
          @click:clear="search = ''"
        />
      </v-card-text>

      <!-- Scrollable Table Container -->
      <v-card-text class="overflow-y-auto pa-0" style="margin-top:10px;max-height: 60vh;">
        <v-data-table
          :headers="tableHeaders"
          :items="filteredUsers"
          :search="search"
          fixed-header
        >
          <template v-slot:item.image="{ item }">
            <div class="ma-2">
              <v-avatar size="60">
                <img
                  :src="item.contactCard.profilePicture
                    ? item.contactCard.profilePicture.urlThumb
                    : 'contact-placeholder.jpg'"
                >
              </v-avatar>
            </div>
          </template>
          <template v-slot:item.contactCard.stakeholder.name="{ item }">
            <div>
              {{ item.contactCard.stakeholder ? item.contactCard.stakeholder.name : '-' }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <!-- Fixed Footer -->
      <v-card-actions class="pt-2">
        <v-spacer></v-spacer>
        <v-btn @click="close">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TeamUsersDialog',
  props: {
    value: Boolean,
    team: Object,
    users: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    search: '',
    tableHeaders: [
      { text: '', value: 'image', sortable: false },
      { text: 'First Name', value: 'contactCard.firstName' },
      { text: 'Last Name', value: 'contactCard.surname' },
      { text: 'Email', value: 'contactCard.email' },
      { text: 'Job Title', value: 'contactCard.jobTitle' },
      { text: 'Stakeholder', value: 'contactCard.stakeholder.name' }
    ]
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    filteredUsers() {
      if (!this.search) {
        return this.users;
      }

      const searchTerm = this.search.toLowerCase();
      return this.users.filter(user => {
        const contactCard = user.contactCard;
        return (
          (contactCard.firstName && contactCard.firstName.toLowerCase().includes(searchTerm)) ||
          (contactCard.surname && contactCard.surname.toLowerCase().includes(searchTerm)) ||
          (contactCard.jobTitle && contactCard.jobTitle.toLowerCase().includes(searchTerm)) ||
          (contactCard.email && contactCard.email.toLowerCase().includes(searchTerm)) ||
          (contactCard.stakeholder && contactCard.stakeholder.name.toLowerCase().includes(searchTerm))
        );
      });
    }
  },
  methods: {
    close() {
      this.search = '';
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.v-data-table {
  width: 100%;
}
</style>
