<template>
  <v-dialog v-model="show" width="500">
    <v-card style="display: flex; flex-direction: column;">
      <!-- Fixed Header -->
      <v-card-title class="white--text primary">
        Remove Stakeholder
      </v-card-title>

      <!-- Content -->
      <v-card-text class="pa-4">
        <div class="text-subtitle-1">
          Are you sure you want to remove this stakeholder from this campaign?
          <br/>
          <br/>
          <span class="font-italic">
            Note: Stakeholder users will still remain part of the campaign
          </span>
        </div>
      </v-card-text>

      <!-- Fixed Footer -->
      <v-card-actions class="pt-2">
        <v-spacer></v-spacer>
        <v-btn @click="$emit('cancel')">
          Cancel
        </v-btn>
        <v-btn 
          color="error" 
          @click="$emit('confirm')"
        >
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteStakeholderDialog',
  
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
